import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineUsergroupAdd,
  AiOutlineUnorderedList,
} from 'react-icons/ai';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { RiLogoutCircleLine, RiAdminLine } from 'react-icons/ri';
import { MdOutlineHowToVote } from 'react-icons/md';
import CandidateAvatar from '../assets/images/user_avatar.png';
import Avatar from '../assets/images/no_avatar.png';

const links = [
  {
    id: 1,
    title: 'MAIN',
    listItems: [
      {
        id: 1,
        title: 'Homepage',
        path: '/',
        icon: <AiOutlineHome className="icon" />,
      },
      {
        id: 2,
        title: 'Profile',
        path: '/voters/:id',
        icon: <AiOutlineUser className="icon" />,
      },
    ],
  },
  {
    id: 2,
    title: 'LIST',
    listItems: [
      {
        id: 1,
        title: 'Candidates',
        path: '/candidates',
        icon: <AiOutlineUsergroupAdd className="icon" />,
      },
      {
        id: 2,
        title: 'Elections',
        path: '/elections',
        icon: <AiOutlineUnorderedList className="icon" />,
      },
      {
        id: 3,
        title: 'Voters',
        path: '/voters',
        icon: <HiOutlineUserGroup className="icon" />,
      },
      {
        id: 4,
        title: 'Vote',
        path: '/vote_candidate',
        icon: <MdOutlineHowToVote className="icon" />,
      },
    ],
  },
  {
    id: 3,
    title: 'LOGS',
    listItems: [
      {
        id: 1,
        title: 'Admin',
        path: '/admin',
        icon: <RiAdminLine className="icon white" />,
      },
      {
        id: 2,
        title: 'Logout',
        path: '/sign_in',
        icon: <RiLogoutCircleLine className="icon" />,
      },
    ],
  },
];

const candidatesRow = [
  {
    id: 1,
    image: CandidateAvatar,
    lastName: 'Snow',
    firstName: 'Jon',
    email: 'jonsnow@example.com',
    phone: '123456789',
    position: 'President',
    politicalParty: 'RPF',
    city: 'Kigali',
    province: 'Kigali',
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit alias et corporis excepturi, quibusdam repellat vel doloremque?',
  },
  {
    id: 2,
    image: CandidateAvatar,
    lastName: 'Peter',
    firstName: 'Parker',
    email: 'peterparker@example.com',
    phone: '123456789',
    city: 'Paris',
    position: 'Deputy',
    politicalParty: 'CDP',
    province: 'Monaco',
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit alias et corporis excepturi, quibusdam repellat vel doloremque?',
  },
  {
    id: 3,
    image: CandidateAvatar,
    lastName: 'Snow',
    firstName: 'Jon',
    email: 'jonsnow@example.com',
    phone: '123456789',
    city: 'Kigali',
    position: 'President',
    politicalParty: 'PL',
    province: 'Kigali',
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit alias et corporis excepturi, quibusdam repellat vel doloremque?',
  },
  {
    id: 4,
    image: CandidateAvatar,
    lastName: 'Snow',
    firstName: 'Jon',
    email: 'jonsnow@example.com',
    phone: '123456789',
    city: 'Kigali',
    position: 'President',
    province: 'Kigali',
    politicalParty: 'PVDR',
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit alias et corporis excepturi, quibusdam repellat vel doloremque?',
  },
  {
    id: 5,
    image: CandidateAvatar,
    lastName: 'Snow',
    firstName: 'Jon',
    email: 'jonsnow@example.com',
    phone: '123456789',
    city: 'Kigali',
    position: 'Deputy',
    province: 'Kigali',
    politicalParty: 'PS',
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit alias et corporis excepturi, quibusdam repellat vel doloremque?',
  },
  {
    id: 6,
    image: CandidateAvatar,
    lastName: 'Delphin',
    firstName: 'Kaduli',
    email: 'delphinkaduli@example.com',
    phone: '123456789',
    city: 'Kigali',
    position: 'Deputy',
    province: 'Kigali',
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit alias et corporis excepturi, quibusdam repellat vel doloremque?',
  },
];

const elections = [
  {
    id: 1,
    title: 'President',
    candidates: [
      {
        id: 1,
        image: Avatar,
        firstName: 'Joseph',
        lastName: 'MacGarret',
        votes: [1, 2, 3],
      },
      {
        id: 2,
        image: Avatar,
        firstName: 'William',
        lastName: 'MacGarret',
        votes: [2],
      },
      {
        id: 3,
        image: Avatar,
        firstName: 'Jacob',
        lastName: 'MacGarret',
        votes: [],
      },
    ],
    votes: ['1', '2', '3'],
  },
  {
    id: 2,
    title: 'Deputy',
    candidates: [
      {
        id: 1,
        image: Avatar,
        firstName: 'Isaac',
        lastName: 'MacGarret',
        votes: [],
      },
    ],
    votes: [],
  },
];

const voters = [
  {
    id: 1,
    image: Avatar,
    firstName: 'Phil',
    lastName: 'Phoden',
    voteCode: 123456,
    hasVoted: false,
    votedCandidate: 'Isaac',
  },
  {
    id: 2,
    image: Avatar,
    firstName: 'James',
    lastName: 'Rodriguez',
    voteCode: 123456,
    hasVoted: false,
    votedCandidate: 'Isaac',
  },
  {
    id: 3,
    image: Avatar,
    firstName: 'Muhamed',
    lastName: 'Salah',
    voteCode: 123456,
    hasVoted: false,
    votedCandidate: 'Isaac',
  },
];

export {
  links,
  candidatesRow,
  elections,
  voters,
};
